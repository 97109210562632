import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const EventTermsAndConditions = () => {
  return (
    <Layout>
      <SEO title="Events Terms And Conditions" />
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <b>
          <span
            style={{ fontSize: "18.0pt", lineHeight: "115%", color: "black" }}
          >
            User Event Agreement
          </span>
        </b>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <b>
          <span
            style={{ fontSize: "18.0pt", lineHeight: "115%", color: "black" }}
          >
            &nbsp;
          </span>
        </b>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
          Talsho Technologies LLP
          <span style={{ color: "black" }}> having its office at</span> No.47,
          Siri Apartments Flat no. F3, 6<sup>th</sup> main, 7<sup>th</sup>{" "}
          cross, near Malleshwaram Police Station, Malleshwaram, Bangalore,
          Karnataka<span style={{ color: "black" }}>, </span>560003, India
          <span style={{ color: "black" }}> bearing </span>LLP Identification
          Number:AAU-6540
          <span style={{ color: "black" }}>
            , owns and operates this content sharing, social networking platform
            under the brand name <b>‘Talsho’</b>, (the “<b>Service(s)</b>” or “
            <b>we</b>” or{" "}
          </span>
          “
          <b>
            <span style={{ color: "black" }}>us</span>”
          </b>
          <span style={{ color: "black" }}>
            ). The Services are made available by way of mobile application
            along with associated mobile sites, applications and other variants
            (“<b>Platform</b>”).
          </span>
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          This document is an electronic record in terms of the Information
          Technology Act, 2000 and rules there under as applicable and various
          other statues.{" "}
        </span>
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "#050505" }}
        >
          This user agreement (“<b>User Agreement</b>”) shall be read in
          conjunction and together with the Privacy Policy, in relation to Your
          access to and use of the Services
        </span>
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "#666666" }}
        >
          .{" "}
        </span>
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          This User Agreement is generated by a computer system and does not
          require any physical or digital signatures.
        </span>
      </p>
      <p
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          This is a legal and binding agreement between you, the user ("
          <b>User</b>" or "<b>You</b>" or “<b>Your</b>”) of the Services and ‘
        </span>
        <span style={{ fontSize: "12.0pt", lineHeight: "115%" }}>
          Talsho Technologies LLP’
          <span style={{ color: "black" }}>
            {" "}
            stating the terms that govern Your use of the mobile application
            along with associated mobile sites, applications and other variants
            (“<b>Platform</b>”). BY ACCEPTING THESE TERMS OF USE IN ANY MANNER
            OR ACCESSING THIS APPLICATION, YOU CONSENT, AGREE AND UNDERTAKE TO
            ABIDE, BE BOUND BY AND ADHERE TO THE TERMS OF USE AND IF YOU DO NOT
            AGREE TO THESE TERMS OF USE, YOU ARE NOT ENTITLED TO ACCESS/ AVAIL
            OF / USE THE SERVICES AND ANY USE THEREAFTER SHALL BE UNAUTHORIZED.
          </span>
        </span>
      </p>
      <p style={{ textAlign: "justify", lineHeight: "115%", border: "none" }}>
        <span
          style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
        >
          &nbsp;
        </span>
      </p>
      <p style={{ textAlign: "justify", lineHeight: "115%", border: "none" }}>
        <b>
          <span
            style={{ fontSize: "15.0pt", lineHeight: "115%", color: "black" }}
          >
            Terms Of Use
          </span>
        </b>
      </p>
      <ol style={{ marginTop: "0in" }}>
        <li
          style={{
            marginTop: "46.0pt",
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            Apple is not responsible in any way for the event and is not a
            sponsor of the event.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            You shall not go overboard while using or while intending to use the
            app or any of the services provided by Talsho/ the team of Talsho.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            Due precautions and caution is to be taken care of and ensured at
            all times in connection to services provided hereunder or the usage
            thereof.{" "}
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            You shall take complete responsibility for your actions while using
            or in connection to the usage of the services and Talsho/ the team
            of Talsho is not responsible nor does it hold any kind of
            responsibility.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            All the rights including but not limited to the intellectual
            property rights, copyrights and all other allied, ancillary and
            subsidiary rights in any and all media in and to the photographs,
            tapes, movies and recordings of everything the applicant say and do
            during the pre-auditions and auditions of the Contest, Post Contest
            Shoot, Promotional Activity and/or otherwise related to the Contest
            shall vest with Talsho absolutely for perpetuity, throughout the
            world.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            Comments of the applicants or materials/photographs/images sent to
            Talsho including feedback and other testimonials of any kind shall
            be deemed to be non-confidential. Talsho is free to reproduce,
            distribute and publicly display such feedback and voting entries
            without limitations or obligation of any kind.Talsho is also free to
            use any ideas, concepts, know-how or techniques contained in such
            feedback for any purpose. Talsho reserves the right to analyze and
            summarize data that we collect and also reserves the right to
            publish, distribute, share summaries of the data collected with
            sponsors or other business partners without limitations or
            obligations of any kind.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            Although Talsho attempts to ensure that all information contained on
            the Show website is error-free, we accept no liability for
            omissions, and reserve the right to change or alter the content of
            the site at any time.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            The schedule of events and qualification rounds are subject to
            change at the discretion of Talsho. In case any event is
            rescheduled, Talsho will make a reasonable attempt to give as much
            advance notice as possible, without being liable for this reason.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            Talsho shall not be responsible for non-completion / non-occurrence
            of any stage of the Contest as planned
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            It is the sole responsibility of the applicants to ensure that they
            are entitled to participate in this Show/Contest as per the
            applicable law.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            We are fully committed to following laws related to the collection
            of data and to our carefully considered policy on data collection.
            You are required to give us the correct details about yourself and
            Talsho will not be liable for any damages directly or indirectly for
            any wrong / incomplete details submitted.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            The decision of Talsho and / or its representatives, employees,
            directors, officers or agents with respect to the voting, any matter
            related thereto including the choice of questions or the manner of
            choosing of winners will be final and binding on all participants
            and no questions, correspondence, enquiries, etc. on the manner of
            conduct of the Contest from any party whatsoever will be
            entertained.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            Talsho reserves the right to cancel or postpone the Contest/Show
            without giving any reason and shall not be held liable for any such
            cancellation or postponement.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            To the extent permitted by law, Talsho or its representatives,
            employees, directors, officers or agents, shall not be liable for
            any loss suffered or sustained, to person or property including, but
            not limited to, consequential (including economic) loss by reason of
            any act or omission, deliberate or negligent on the part of Talsho
            or its representatives, employees, directors, officers or agents.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            The applicants agree that they shall hold Talsho, its affiliates,
            its group companies, their employees, officers, directors or any
            other person harmless from and against any injury/damage/harm/loss
            etc. suffered by them, in any manner whatsoever in connection with
            participating in the Show/Contest and waives all rights to file in
            person/ through any family member and/or third Participant any
            applications, criminal and/or civil proceedings in any courts or
            forum in India to claim any damages or relief.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            The interpretation and implementation of the Terms Of Use shall be
            at the sole discretion of Talsho. The decisions of Talsho shall be
            final and binding, and not subject to challenge or appeal.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            The participants agree and undertake to indemnify Talsho against any
            loss, damage, liability, etc. suffered by it on account of
            non-fulfillment or breach of any of these terms by the participant.
          </span>
        </li>
        <li
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            In no event shall Talsho’s liability for any claim, made by any
            participant for any reason, exceed Rs.500/-.
          </span>
        </li>
        <li
          style={{
            marginBottom: "50.0pt",
            textAlign: "justify",
            lineHeight: "115%",
          }}
        >
          <span
            style={{ fontSize: "12.0pt", lineHeight: "115%", color: "black" }}
          >
            These terms and conditions shall be governed by &amp; construed in
            accordance with the applicable laws in India. All claims, disputes,
            legal proceedings or litigation arising in connection with the
            Services will be brought solely in the competent courts of
            jurisdiction located in Kurnool, Andhra Pradesh, India and its
            respective superior courts and You consent to the jurisdiction of
            and venue in such courts and waive any objection as to inconvenient
            forum.
          </span>
        </li>
      </ol>
      <p
        style={{
          marginTop: "46.0pt",
          marginRight: "0in",
          marginBottom: "50.0pt",
          marginLeft: ".5in",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <span style={{ fontSize: "12.0pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginTop: "46.0pt",
          marginRight: "0in",
          marginBottom: "50.0pt",
          marginLeft: ".5in",
          textAlign: "justify",
          lineHeight: "115%",
        }}
      >
        <span style={{ fontSize: "12.0pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginLeft: ".5in",
          textAlign: "justify",
          lineHeight: "115%",
          border: "none",
        }}
      >
        <span style={{ fontSize: "12.0pt", lineHeight: "115%" }}>&nbsp;</span>
      </p>
    </Layout>
  )
}

export default EventTermsAndConditions
